import { ToastContainer, toast } from "react-toastify";

const ToastProvider = () => {
  return (
    <ToastContainer
      position={toast.POSITION.BOTTOM_LEFT}
      autoClose={1000}
      hideProgressBar
      icon={false}
    />
  );
};

export default ToastProvider;
